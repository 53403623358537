import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { Spinner, PrimaryBtn } from 'zyppd-components'

const NotFoundPage = () => {

  const [awaiting, setAwaiting] = useState(true);

  async function getShop() {

    const location = window.location.pathname
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: location
      })
    };

    try {

      const api = await fetch("https://us-central1-booker-65d2a.cloudfunctions.net/app/get_shop", params)

      const response = await api.json()
      console.log({ response })

      if (response.url === '') setAwaiting(false)
      if (response.url) {
        window.location.href = `https://app.zyppd.in/${response.url}`
      }

      return response

    } catch (error) {
      setAwaiting(false)
      return console.log("ERROR => ", { error })
    }
  }

  useEffect(() => {
    getShop()
  }, [])


  return (

    <Layout>
      {
        awaiting ? (

          <LoadingScreen />
        )
          : (
            <>
              <SEO title="404: Not found" />

              <h1>NOT FOUND</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </>
          )
      }
    </Layout>
  )
}

export default NotFoundPage


const LoadingContainer = styled.div`
  min-height: 100vh; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  flex-direction: column;
 
`

function LoadingScreen() {


  return (
    <LoadingContainer>
      <Spinner />
      <h3 style={{ marginTop: '1em' }}>Finding your shop</h3>
    </LoadingContainer>
  )
}
